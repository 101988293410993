<!--
File: Report_4_RoadLengthByTraffic.vue
Description: produces the report for printing.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown class='region-select-dropdown' :label="$t('label.select_region')" v-model="selectedRegion"
            :initial_value="selectedRegion" @input='onRegionChange' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <DepsDropdown class='region-select-dropdown' :label="$t('label.select_deu')" v-model="selectedDeu"
            :initial_value="selectedDeu" :region_id="selectedRegion" @input='onDeuChange' :disabled='!selectedRegion' />
        </div>
        <!--div class="md-layout-item md-small-size-100 md-size-20">
          <RoadClassesDropdown :label="$t('road_network.road_class')" v-model="selectedRoadClass"
            :initial_value=selectedRoadClass @input='onRoadClassChange' />
        </div-->
        <div class="md-layout-item md-small-size-100 md-size-10">
          <md-field>
            <md-input type="number" class="mb-3" clearable style="width: 100px" :placeholder="$t('label.startYear')"
              v-model="startYear" />
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-10">
          <md-field>
            <md-input type="number" class="mb-3" clearable style="width: 100px" :placeholder="$t('label.endYear')"
              v-model="endYear" />
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }} </md-button>
          <md-button class="md-raised md-success" @click="exportToExcel" :disabled="btnDisabled">
            {{ $t('buttons.excel_export') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('budget.year')">
            {{ item.survey_year }}
          </md-table-cell>
          <md-table-cell style="min-width: 200px" v-if="!selectedRegion" :md-label="$t('road_network.region')">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell v-if="!selectedDeu" :md-label="$t('road_network.dep')">
            {{ item.deu_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.very_very_high')" md-numeric>
            {{ item.length_km_above_24000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.very_high')" md-numeric>
            {{ item.length_km_between_12001_and_24000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.high')" md-numeric>
            {{ item.length_km_between_7001_and_12000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.medium')" md-numeric>
            {{ item.length_km_between_3001_and_7000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.low')" md-numeric>
            {{ item.length_km_between_1001_and_3000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.very_low')" md-numeric>
            {{ item.length_km_between_251_and_1000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.very_very_low')" md-numeric>
            {{ item.length_km_below_250 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.total_km')" md-numeric>
            {{ item.total_km | numFormat(3) }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="md-layout">
        <!--<div>{{ $t('total_regions: ') }}: {{ total_regions }}&nbsp; </div>
            <div>{{ $t('total_deu: ') }}: {{ total_deu }}&nbsp; </div>-->
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import DepsDropdown from '../Dropdowns/DepsDropdown.vue'
//import RoadClassesDropdown from '../Dropdowns/RoadClassesDropdown.vue'
import { numFormat } from "@/store/helpers/format_helpers"
import { customSortMixin } from '@/mixins/customSortMixin'
import printReport from '@/mixins/printMixin'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
  name: 'report-road-length-by-traffic',
  mixins: [customSortMixin, printReport],
  data() {
    return {
      selectedRegion: 0,
      selectedDeu: 0,
      startYear: null,
      endYear: new Date().getFullYear(),       // 2024,
      selectedRoadClass: '',
      btnDisabled: true,

      showSpinner: false,
      currentSort: 'region_description',
      currentSortOrder: 'asc',
      regionDescr: null,
      deuDescr: null
    }
  },
  components: {
    RegionsDropdown,
    DepsDropdown,
    //RoadClassesDropdown,
  },

  mounted() {
    this.startYear = new Date().getFullYear() - 5
  },

  methods: {
    reloadData() {
      this.showSpinner = true
      this.btnDisabled = true
      const report_filters = {
        start_year: this.startYear,
        end_year: this.endYear,
        region_id: this.selectedRegion,
        road_class: this.selectedRoadClass,
        deu_id: this.selectedDeu
      }
      this.$store.dispatch('REPORT_4_LENGTH_BY_TRAFFIC', report_filters).then(() => {
        this.btnDisabled = false
        this.showSpinner = false
      })

    },
    print() {
      this.showSpinner = true
      this.printReport(this.getPrintHeader, this.getTableHeaders, this.getTableRows)
      this.showSpinner = false
    },

    setWorkSheetHeader(worksheet) {
      let titleRow = worksheet.insertRow(1, ['', this.$t('label.reports_title')])
      titleRow.font = { bold: true, size: 16 };
      titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('B1:I1');

      titleRow = worksheet.insertRow(2, ['',
        `${this.$t('label.report4_title')} ${this.startYear} - ${this.endYear}`
      ]);
      titleRow.font = { size: 14 };
      titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('B2:I2');

      if (this.headerTitles.length > 0) {
        titleRow = worksheet.insertRow(3, ['', this.headerTitles.join(', ')]);
        titleRow.font = { size: 14 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('B3:I3');
      }
    },

    fillWorkSheet(workbook, workbookName) {
      const headerCellFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0070C0' } } // Blue
      const worksheet = workbook.addWorksheet(workbookName);

      // Set the table headers
      const columns = this.getWorksheetColumns
      worksheet.columns = columns

      // Apply formatting to the table headers first  row
      let newRow = worksheet.getRow(1);
      newRow.font = { bold: true, color: { argb: 'FFFFFFFF' } }
      newRow.fill = headerCellFill
      newRow.alignment = { vertical: 'middle', horizontal: 'center' }

      // Number formatting for the columns
      columns.forEach(item => {
        if (item.num) worksheet.getColumn(item.key).numFmt = '#,##0.000'
      })

      this.report.forEach(item => {
        worksheet.addRow(item);
      });

      // Set the worksheet header, should be after filling the table
      this.setWorkSheetHeader(worksheet)
    },

    async exportToExcel() {
      // Create new Excel file
      const workbook = new ExcelJS.Workbook();
      const wbName = this.$t('route.report_length_by_intensity')
      this.fillWorkSheet(workbook, wbName)

      // Save
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${wbName}.xlsx`);
    },

    onRegionChange(region_id, regionDescr) {
      //      if (region_id) this.selectedDeu = 0
      this.regionDescr = regionDescr
    },
    onDeuChange(deu_id, deuDescr) {
      this.deuDescr = deuDescr
    },
  },

  computed: {
    ...mapState({
      report_4_data: (state) => state.Reports.report_4_data,
    }),

    ...mapGetters([]),

    report() {
      let res = !this.selectedRegion ? this.report_4_data : this.report_4_data.filter((item) => {
        return item.region_id == this.selectedRegion
      })
      if (this.selectedDeu) {
        res = res.filter((item) => { return item.deu_id == this.selectedDeu })
      }

      return this.customSort(res
        .map(item => { return { ...item, deu_description: `${this.$t('road_network.dep')}-${item.deu_description}` } }),
        'deu_description')
    },

    getWorksheetColumns() {
      let res = [
        { header: this.$t('budget.year'), key: 'survey_year', width: 10 },
        { header: this.$t('label.very_very_high'), key: 'length_km_above_24000', width: 15, num: true },
        { header: this.$t('label.high'), key: 'length_km_between_12001_and_24000', width: 15, num: true },
        { header: this.$t('label.medium'), key: 'length_km_between_7001_and_12000', width: 15, num: true },
        { header: this.$t('label.low'), key: 'length_km_between_3001_and_7000', width: 15, num: true },
        { header: this.$t('label.very_low'), key: 'length_km_between_1001_and_3000', width: 15, num: true },
        { header: this.$t('label.very_high'), key: 'length_km_between_251_and_1000', width: 15, num: true },
        { header: this.$t('label.very_very_low'), key: 'length_km_below_250', width: 15, num: true },
        { header: this.$t('road_network.total_km'), key: 'total_km', width: 15, num: true },
      ];

      if (!this.selectedDeu) {
        res.unshift({ header: this.$t('road_network.dep'), key: 'deu_description', width: 10 });
      }

      if (!this.selectedRegion) {
        res.unshift({ header: this.$t('road_network.region'), key: 'region_description', width: 30 });
      }
      return res
    },

    headerTitles() {
      const titleFiltersArray = []

      if (this.selectedRegion) {
        titleFiltersArray.push(this.$t('label.from_region', { name: this.report[0].region_description }));
      }

      if (this.selectedDeu) {
        titleFiltersArray.push(`${this.$t('road_network.dep')}: ${this.report[0].deu_description}`);
      }
      return titleFiltersArray
    },

    getPrintHeader() {
      const titleFilters = this.headerTitles.length > 0 ? `<br>(${this.headerTitles.join(', &nbsp;')})` : '';

      return `<h4 style='text-align:center'>${this.$t('label.reports_title')}</h4>
        <h4 style='text-align:center'>
          ${this.$t('label.report4_title')}
          ${this.startYear} - ${this.endYear}
          ${titleFilters}
        </h4>`;
    },

    getTableHeaders() {
      const columns = this.getWorksheetColumns
      return `<tr>${columns.map(col => `<th>${col.header}</th>`).join('')}</tr>`
    },

    getTableRows() {
      const columns = this.getWorksheetColumns

      return this.report.map(item => {
        return `<tr>${columns.map(col => col.num
          ? `<td class='numCell'>${numFormat(item[col.key], 3)}</td>`
          : `<td>${item[col.key]}</td>`).join('')}
        </tr>`;
      }).join('');
    },
  },

  watch: {
    startYear(value) {
      if (!value) this.startYear = new Date().getFullYear() - 5
      this.reloadData()
    },

    endYear(value) {
      if (!value) this.endYear = new Date().getFullYear()
      this.reloadData()
    },
  }
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  font-size: 14px !important;
  text-align: center !important;
  overflow: visible !important;
  white-space: normal !important;
  padding-right: unset !important;
}

.md-table-cell.md-numeric {
  padding: 0px !important;
  padding-right: 18px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>